#portfolio{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}



.portfolio-heading{
    font-weight: 700;
    font-size: 45px;
    padding-bottom: 25px;
    text-align: center;
}

.portfolio-items{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.work-display{
    border-radius: 12px;
    background-color: #05060c;
    height: 35rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 15px 20px;
    border: 1px solid #106ede;
}

.url {
    width: 100%;
    background-color: #4d5084;
    border-radius: 12px;
    padding: 5px;
    text-align: center;
}

.url a, .url a:hover, .work-description h2,  .work-description h2:hover{
    color: #d6bef1;
}


.work-info{
    display: flex;
    gap: 2rem;
    width: 100%;
}

.work-description h2{
    font-size: 20px;
    font-weight: 700;
}

.work-description p{
    font-size: 15px;
    text-align: center;
    padding-top: 15px;
}

.work-description{
    height: 25rem;
    width: 25%;
    background-color: #4d5084;
    border-radius: 12px;
    padding: 15px 10px;
    text-align: center;
}

.work-demo{
    height: 25rem;
    width: 75%;
    background-color: #4d5084;
    border-radius: 12px;
    text-align: center;
}


.work-demo img{
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

/* WORK LIST */

.work-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.work-list button{
    background-color: rgba(3, 3, 3, 0.555);
    list-style: none;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid white;
    border-radius: 12px;
    width: 18%;
    height: 50px;
    font-size: 15px;
    padding: 30px 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    color: gray;
}

.work-list button svg{
    color: gray;
}

.work-list button:hover, .active-btn{
    color: #106ede !important;
    border: 1px solid #106ede !important;
}

.work-list button svg:hover{
    color: #106ede;
}


@media screen and (min-width: 360px) and (max-width: 1024px) {

    #portfolio{
        padding-bottom: 80px;
    }

    .portfolio-heading{
        background-color: black;
        font-size: 30px;
    }

    .work-list{
        flex-direction: column;
    }

    .work-list button{
        width: 100%;
    }

    .work-display{
        height: 100%;
    }

    .work-info{
        flex-direction: column-reverse;
    }


    .work-description, .work-demo{
        width: 100%;
        height: 100%;
    }

    .url a{
        font-size: 13px;
    }

 }
 