.wrapper{
    display: inline-flex;
    margin-top: 20px;
      position: fixed;
      left: 75%;
      backdrop-filter: blur(15px);
      background: rgba(0,0,0,.3);
      border-radius: 3rem;
      padding: 0.7rem 1.7rem;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: -webkit-max-content;
      width: max-content;
      z-index: 2;
  }
  .wrapper .icon{
    color: #d6bef1;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .wrapper .icon span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .wrapper .icon span svg{
    line-height: 60px;
    font-size: 25px;
  }
  .wrapper .icon .tooltip{

    position: absolute;
    text-align: center;
    width: 9rem;
    top: 0;
    z-index: 1;
    color: #fff;
    padding: 10px 18px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 25px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .instagram > .tooltip{
    width: 10rem !important;
  }
  
  .wrapper .icon:hover .tooltip{
    top: 65px;
    opacity: 1;
    pointer-events: auto;
  }
  .wrapper .icon:hover span{
    color: #fff;
  }
  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip{
    text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
  }
  .wrapper .effect:hover span,
  .wrapper .effect:hover .tooltip,
  .wrapper .effect:hover .tooltip:before{
    background: #0f111f;
  }
 
  @media screen and (min-device-width: 360px) and (max-device-width: 768px) {

      .wrapper{
        left: 50%;
        bottom: 0;
      }

      .tooltip{
        display: none;
      }

      .wrapper .icon{
        margin: 0;
      }
   }

