@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600&display=swap');


.cover-container{
    height: 100vh;
    width: 100%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    flex-direction: column;
     }

     .back-video{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100vw;
     }

.cover{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 8%;
}

/* HEADER TEXT */
.text-animation{
    margin-right: 10%;
}

.start{    
    font-size: 32px !important;

}

.heading-text{
    color: white;
    font-size: 50px;
    
}

.heading-name{
    font-family: 'Teko', sans-serif;
    transition: all 0.5s;
    -webkit-text-stroke: 4px #d6f4f4;
    font-variation-settings: "wght" 500, "ital" 1;
    font-size: 8rem;
    text-align: center;
    color: transparent;
    text-shadow: 10px 10px 0px #07bccc,
      15px 15px 0px #e601c0,
      20px 20px 0px #e9019a,
      25px 25px 0px #f40468,
      45px 45px 10px #482896;
    cursor: pointer;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.heading-name:hover{
    font-variation-settings: "wght" 100, "ital" 0;
    text-shadow: none;
}

.avatar{
    width: 100%;
}

.container{
    overflow: hidden;
}
.end2, .middle1, .middle2{
    display: inline-block;
    overflow:hidden;
    height: 1.5em;
    font-size: 3rem;
    font-family: 'Teko', sans-serif;
}
.end2{
    position:absolute;
    padding-left: 7px;
    color: rgb(11, 130, 235);
}
.middle1, .middle2{
    color: #cfcfcf
}
.middle1 {
    animation: ani1 5s infinite;
    animation-delay: -3s;
    max-width: 0;
}
.middle2 {
    animation: ani2 5s infinite;
    animation-delay: -3s;
    max-width: 0;
}

@keyframes ani1 {
    0% {
    max-width: 100%;
  }
  
  25% {
    max-width: 0;
  }
  
  75% {
    max-width: 0;
  }
  
  90% {
    max-width: 100%;
  }
  
  100% {
    max-width: 100%;
  }
}

@keyframes ani2 {
    0% {
        max-width: 0;
    }

    25% {
        max-width: 0;
    }

    50% {
        max-width: 500px;
    }

    75% {
        max-width: 0;
    }

    100% {
        max-width: 0;
    }
}

/* BUTTON */

.buttons-container{
    display: flex;
    justify-content: center;
    gap: 15px;
}

.cv-button{
    min-width: 169px;
    color: #fff;
    background-image: -webkit-linear-gradient(30deg, rgba(3,78,145,.58) 50%, transparent 50%);
    background-image: linear-gradient(30deg, rgba(3,78,145,.58) 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.cv-button:hover{
    background-position: 100%;
  color: #d6bef1;
}

.cv-button a{
    color: #d6bef1;
    font-size: 20px;
}

.social-icons{
    font-size: 28px;
    padding-left: 10px;
    padding-top: 15px;
}

.social-icons a{
    color: white;
    margin-right: 20px;
}
.social-icons a:hover{
    color: #d6bef1;
}

@media screen and (min-device-width: 360px) and (max-device-width: 1024px) { 
    .start{
        margin-left: -70px;
    }

    .back-video{
        width: auto;
    }

    .end2, .middle1, .middle2{
        font-size: 2rem;
    }

    .heading-text{
        font-size: 25px;
    }

    .heading-name{
        font-size: 4rem;
        padding-bottom: 20px;
    }

    .cv-button a{
        font-size: 16px;
    }
}
