#about{
    display: flex;
    justify-content: center;
    color: #d6bef1;
 }


.avatar-about{
    display: flex;
    justify-content: center;
}
.avatar-about img{
    width: 100%;
}

#about h2{
    font-weight: 700;
    font-size: 50px;
    padding-bottom: 20px;
}

.about-me{
    height: 100vh;
    align-items: center;
    
}


.about-me-p{
    font-size: 20px;
}

.bio{
    width: 530px;
}

.bio > a {
    color: #c6c5ed;
}

.web-items{
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
}

.web-item h3{
    font-weight: 600;
    padding-top: 10px;
    font-size: 30px;
}

.web-item svg{
    color: white;
    font-size: 50px;
    background-color: var(--purple);
    height: 100px;
    width: 50%;
    padding: 10px;
    border-radius: 50%;
}


@media screen and (min-width: 360px) and (max-width: 1024px) { 
    
    #about{
        padding: 45px 10px;
    }

    .about-text{
        background-color: black;
    }

    #about h2{
        font-size: 30px;
        text-align: center;
    }

    .about-me{
        flex-direction: column;
        height: 100%;
    }

    .about-me-p{
        font-size: 16px;
        text-align: center;
    }

    .avatar-about{
        padding-top: 50px;
    }


    #about .col, #about .bio{
        width: 100% !important;
    }
}