#stack{
    color: #d6bef1;
    background: linear-gradient(180deg,rgba(3,78,145,0) 2.44%,rgba(3,78,145,.35) 24.17%,rgba(3,78,145,.58) 33.87%,rgba(3,78,145,.644) 47.16%,rgba(3,78,145,.633) 58.77%,rgba(3,78,145,.527) 71%,rgba(3,78,145,0) 98.43%);
}

.stack-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }

.stack-heading{
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    padding-bottom: 60px;
}

.stack__list, .stack__list ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.stack__list {
    width: 100%;
}

.stack__list ul {
    gap: 20px;
    width: 100%;
}

.stack__list ul li, .item-list {
    color: white;
    align-items: center;
    background: var(--purple);
    border-radius: 0.3rem;
    box-shadow: 7px 7px 0 0 #1a0447;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    justify-content: center;
    padding: 10px;
    width: 120px;
    transition: 0.5s;
}

.item-list:hover{
    background-color: #d6bef1;
    color: var(--purple);
}


.stack__list ul li svg {
    font-size: 3rem;
}

.stack-img{
    text-align: end;
}

.stack-img img{
    width: 80%;
}


@media screen and (min-width: 360px) and (max-width: 1024px) { 

    #stack{
        padding-top: 145px;
        padding-bottom: 145px;
    }

    .stack-container{
        height: 100%;
    }

    #stack h2{
        font-size: 30px;
    }

    .stack-container row{
        flex-direction: column;
    }

    ul{
        padding: 0;
    }

    .stack__list ul li, .item-list{
        width: 100px;
    }

    .stack-img{
        display: none;
    }
}